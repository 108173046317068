import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import TileSection from "../components/tileSection"
import Slider from "../components/swiper"
import IconSection from "../components/iconSection"
import ImageGridSection from "../components/imageGridSection"
import AchievementsSection from "../components/achievementsSection"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

import "./index.css"

const IndexPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <section id="home-landing" className="page-section">
        <AnimationWrapper>
          <Slider data={data.page.frontmatter.slides} />
        </AnimationWrapper>
      </section>

      {data.page.frontmatter.text_sections[0] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[0]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="homepage-tiles" className="page-section">
        <Container>
          <AnimationWrapper>
            <TileSection data={data.page.frontmatter.tiles} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="homepage-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icons_section[0]} icons={data.page.frontmatter.icons} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_sections[1] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[1]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="partnerzy-grid-section" className="page-section">
        <ImageGridSection location="partnerzy-all" imagesCount={15} btnText="Pokaż więcej >" data={data.page.frontmatter.partnerzy_grid}/>
      </section>

      <section id="homepage-achievements">
        <Container>
          <AnimationWrapper>
            <AchievementsSection data={data.page.frontmatter.achievements_section[0]} achievements={data.page.frontmatter.achievements} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_sections[2] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[2]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="klienci-grid-section" className="page-section">
        <ImageGridSection location="klienci" imagesCount={15} btnText="Pokaż więcej >" data={data.page.frontmatter.klienci_grid}/>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section[0]} />
      </section>

      {data.page.frontmatter.text_sections[3] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[3]} />
          </AnimationWrapper>
        </Container>
      }

      {data.page.frontmatter.text_sections[4] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[4]} />
          </AnimationWrapper>
        </Container>
      }

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "index" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slides{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_url
          text
        }
        text_sections{
          title
          title_visible
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
        }
        tiles{
          title
          type
          active
          url
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          description
        }
        icons_section{
          title
          title_visible
          subtitle
          type
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
        }
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        partnerzy_grid{
          title
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          description
        }
        achievements_section{
          title
          title_visible
          subtitle
          type
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          description
        }
        achievements{
          value
          description
        }
        klienci_grid{
          title
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          description
        }
        contact_section{
          title
          description
          type
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
  }
`

export default IndexPage
