import * as React from "react"
import Tile from "./tile"

const TileSection = ( {data, location} ) => {

    return (
      <div className={`${location}-tiles tile-section`}>
        {data.map((tile) => (
            <Tile tile={tile} />
        ))}
      </div>
    )
  }

export default TileSection