import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import TextSection from "./textSection"
import "./achievementsSection.css"

const AchievementsSection = ( { data, achievements, location } ) => {

    return (

      <div className={`${location}-achievements`}>

        <div className="achievements-grid-container page-section">

            {data.image &&
              <div className="image-container">
                <GatsbyImage
                  image={data.image.childImageSharp.gatsbyImageData}
                />
              </div>
            }

            <div className={`achievements ${data.type} ${data.image ? "" : "no-image"}`}>

              <div className="text-section-container">
                {data.title_visible &&
                  <TextSection className="description-medium" data={data} />
                }
              </div>

              <div className={`achievements-grid ${data.type}`}>
                {achievements.map((achievement) => (
                  <div className="achievement-wrapper">
                    <h3 className="achievement-value">
                      {achievement.value}
                    </h3>
                    <p className="achievement-desc">
                      {achievement.description}
                    </p>
                  </div>
                ))}
              </div>

            </div>

        </div>
      </div>

    )
  }

export default AchievementsSection